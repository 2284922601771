import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Writing extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      allPosts: false
    }

    this.moreEssays = this.moreEssays.bind(this)
  }

  moreEssays(e){
    e.preventDefault()
    this.setState({ allPosts: !this.state.allPosts })
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    let posts = data.allMarkdownRemark.edges
    const selectedPosts = posts.filter(post => post.node.frontmatter.selected)
    let allPosts = posts.filter(post => !post.node.frontmatter.selected)

    let text = "fewer"
    if (!this.state.allPosts){ 
      allPosts = allPosts.slice(0, 10)
      text = "more"
    }  

    return (
      <Layout location={this.props.location} title={siteTitle}>

        <SEO 
          title="Writing"
          description="A collection of my essays and other writing." 
        />

        <h1 className="page-header">Selected Essays</h1>
        {selectedPosts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug} className="post">
              <h3><Link to={node.fields.slug}>{title}</Link></h3>
              <small>{node.frontmatter.date}</small>
            </div>
          )
        })}

        <h1 className="page-header">All Other Writing</h1>
        {allPosts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug} className="post">
              <h3><Link to={node.fields.slug}>{title}</Link></h3>
              <small>{node.frontmatter.date}</small>
            </div>
          )
        })}
        <p>Click to see <button onClick={this.moreEssays}>{text} essays</button>.</p>
    
      </Layout>
    )
  }
}

export default Writing

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: {
          type: { ne: "page" }
        }
      }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "D MMM, YYYY")
            title
            description
            selected
          }
        }
      }
    }
  }
`
